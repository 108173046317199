import axios from 'axios';
import { Platform } from 'react-native';
const Config = require('~config/default.env').default;
import AsyncStorage from '@react-native-async-storage/async-storage';
import Toast from 'react-native-toast-message';
import * as Linking from 'expo-linking';
import { SOC } from '~global/constants';
import { storeAsyncData } from '~global/helpers';
import { getVersion } from 'react-native-device-info';
import { fetchSession } from '~global/sociolla.dm.lib/sociolla-analytics';
import { isWebPlatform } from '~global/screens';

let lastUsedDomain = 0;
let lastUsedDomainCart = 0;

export default function setup() {
  axios.interceptors.request.use(
    async (config) => {
      const platform = SOC[Platform.OS];
      if (/android|ios/.test(platform)) {
        config.headers['User-Agent'] = platform;
        config.headers['mobapp-version'] = getVersion();
      }
      config.headers['SOC-PLATFORM'] = platform;
      const userData = JSON.parse(await AsyncStorage.getItem('userData'));
      if (userData && userData.token && !config.url.includes('amazonaws.com')) {
        config.headers.Authorization = `Bearer ${userData.token}`;
      }
      if (isWebPlatform) {
        config.headers['session_id'] = fetchSession();
      } else {
        try {
          let d = new Date().getTime();
          let newGuid = '';
          const guid = await AsyncStorage.getItem('SOCIOLLA_GUID');
          if (!guid) {
            if (
              typeof performance !== 'undefined' &&
              // eslint-disable-next-line no-undef
              typeof performance.now === 'function'
            ) {
              // eslint-disable-next-line no-undef
              d += performance.now(); //use high-precision timer if available
            }
            newGuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
              /[xy]/g,
              function (c) {
                const r = (d + Math.random() * 16) % 16 | 0;
                d = Math.floor(d / 16);
                return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
              },
            );
            AsyncStorage.setItem('SOCIOLLA_GUID', newGuid);
          } else {
            newGuid = await AsyncStorage.getItem('SOCIOLLA_GUID');
          }

          config.headers['session_id'] = fetchSession();
        } catch (e) {
          console.log(e);
        }
      }

      if (config.url.startsWith(Config.MS_CATALOG_API_URL)) {
        config.url =
          Config.MS_CATALOG_API_LIST[lastUsedDomain] +
          config.url.substr(Config.MS_CATALOG_API_URL.length);
        lastUsedDomain =
          lastUsedDomain + 1 >= Config.MS_CATALOG_API_LIST.length
            ? 0
            : lastUsedDomain + 1;
      }

      if (config.url.startsWith(Config.MS_CART_API_URL)) {
        config.url =
          Config.MS_CART_API_LIST[lastUsedDomainCart] +
          config.url.substr(Config.MS_CART_API_URL.length);
        lastUsedDomainCart =
          lastUsedDomainCart + 1 >= Config.MS_CART_API_LIST.length
            ? 0
            : lastUsedDomainCart + 1;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  // Add response interceptors
  axios.interceptors.response.use(
    (response) => {
      // removeAsyncData('@response_interceptors');
      return response;
    },
    (error) => {
      try {
        let message = '';
        let endpointUrl = error?.response?.config?.url
          ? error.response.config.url
          : '';
        if (endpointUrl && endpointUrl.includes('/')) {
          endpointUrl = Linking.parse(endpointUrl);
          endpointUrl = endpointUrl?.path ? `/${endpointUrl.path}` : '';
        }
        const errorCode = error?.response?.data?.errorCode
          ? error?.response?.data?.errorCode
          : '';
        const errorMessage = error?.response?.data?.message
          ? error?.response?.data?.message
          : '';
        const excludedEnpointUrl = ['/auth/login/mobile', '/carts/voucher'];

        message = errorMessage;

        if (
          (endpointUrl.indexOf('/payments') > -1 ||
            endpointUrl.indexOf('/users/me/orders') > -1) &&
          errorCode === 'payment_failed'
        ) {
          message = 'Payment failed, please try again later';
          storeAsyncData('@payment_failed', 'true');
        }

        if (excludedEnpointUrl.includes(endpointUrl)) {
          message = '';
        }
        // ignore error from internal tracking
        if (/[/]sessions[/]me/.test(endpointUrl)) {
          message = '';
        }

        if (error && error.message === 'Network Error') {
          message = 'Network error';
        }
        if (endpointUrl.indexOf('/login') == -1) {
          if (error.response.status === 404) {
            storeAsyncData('@response_interceptors', 'error_404');
          } else if (error.response.status === 500) {
            storeAsyncData('@response_interceptors', 'error_500');
          } else if (error.response.status === 503) {
            storeAsyncData('@response_interceptors', 'error_503');
          }
        }

        if (
          /session expired|maxAge|jwt issuer invalid|invalid signature/gi.test(
            message,
          )
        ) {
          AsyncStorage.removeItem('userData');
        } else if (error?.response?.data?.data?.statusCode === 500) {
          // dont show toast if error status code is 500
        } else {
          if (message) {
            Toast.show({ text1: message, type: 'error' });
          }
        }
      } catch (err) {
        console.log(err);
      }

      return Promise.reject(error);
    },
  );
}
