import { createAsyncThunk } from '@reduxjs/toolkit';
import { Bj } from '~api/bj';

export const getArticleCategories = createAsyncThunk(
  'articles/getArticleCategories',
  async (slug) => {
    const response = await Bj.getArticleCategories(slug);
    response.data.slug = response.data.data.slug;
    return response.data;
  },
);

export const getPostHighlightArticles = createAsyncThunk(
  'articles/getPostHighlightArticles',
  async (slug) => {
    const response = await Bj.getPostHighlightArticles(slug);
    response.data.slug = response.data.data.slug;
    return response.data;
  },
);

export const getPostArticles = createAsyncThunk(
  'articles/getPostArticles',
  async (slug) => {
    const response = await Bj.getPostArticles(slug);
    response.data.slug = response.data.data.slug;
    return response.data;
  },
);

export const getDetailArticles = createAsyncThunk(
  'articles/getDetailArticles',
  async (slug) => {
    const response = await Bj.getDetailArticles(slug);
    response.data.slug = response.data.data.slug;
    return response.data;
  },
);

export const loadMoreDetailArticles = createAsyncThunk(
  'articles/loadMoreDetailArticles',
  async (slug) => {
    const response = await Bj.getDetailArticles(slug);
    response.data.slug = response.data.data.slug;
    return response.data;
  },
);

export const postActionArticle = createAsyncThunk(
  'articles/postActionArticle',
  async (payload) => {
    try {
      const response = await Bj.postActionArticle(payload);
      return response.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
);

export const getCommentsArticle = createAsyncThunk(
  'articles/getCommentsArticle',
  async (payload) => {
    try {
      const response = await Bj.getCommentsArticle(payload);
      return response.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
);

export const postCommentArticle = createAsyncThunk(
  'articles/postCommentArticle',
  async (payload) => {
    try {
      const response = await Bj.postCommentArticle(payload);
      return response.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
);

export const loadMorePostArticles = createAsyncThunk(
  'articles/loadMorePostArticles',
  async (params) => {
    const response = await Bj.loadMorePostArticlesRequest(params);
    response.data.slug = response.data.data.slug;
    return response.data;
  },
);

export const loadMoreCommentsArticle = createAsyncThunk(
  'articles/loadMoreCommentsArticle',
  async ({ id, params }) => {
    const response = await Bj.getLoadMoreCommentsArticle({ id, params });
    response.data.slug = response.data;
    return response.data;
  },
);

export const getTotalPostArticle = createAsyncThunk(
  'articles/getTotalPostArticle',
  async (params) => {
    const response = await Bj.getTotalPostArticle(params);
    return response.data;
  },
);

export const postLikeComment = createAsyncThunk(
  'articles/postLikeComment',
  async (id) => {
    try {
      const response = await Bj.postLikeComment(id);
      return response.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
);

export const reportComment = createAsyncThunk(
  'articles/reportComment',
  async (payload) => {
    try {
      const response = await Bj.reportComment(payload);
      return response.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
);

export const postReplyCommentArticle = createAsyncThunk(
  'articles/postReplyCommentArticle',
  async (payload) => {
    try {
      const response = await Bj.postReplyCommentArticle(payload);
      return response.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
);

export const postLikeCommentReply = createAsyncThunk(
  'articles/postLikeCommentReply',
  async (id) => {
    try {
      const response = await Bj.postLikeCommentReply(id);
      return response.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
);

export const requestDeleteComment = createAsyncThunk(
  'articles/requestDeleteComment',
  async (id) => {
    try {
      const response = await Bj.requestDeleteComment(id);
      return response.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
);

export const editComment = createAsyncThunk(
  'articles/editComment',
  async (id) => {
    try {
      const response = await Bj.editComment(id);
      return response.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },
);
