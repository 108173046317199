/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/prop-types */
import React, { createElement, useEffect } from 'react';
import { TouchableOpacity, View, StyleSheet, Text } from 'react-native';
import Image from '@web/components/common/Image';
import { useSelector, useDispatch } from 'react-redux';
import ActivityIndicator from '@web-components/common/WithLocalSvg';
import { isWeb } from '~global/screens';
import Colors from '~global/Colors';
import { imageCdn, generateFakeCategoryId } from '~global/helpers';
import StringUtils from '~global/utils/string';
const CustomScrollbar = (props) =>
  createElement('div', { className: 'custom-scrollbar' }, props.children);
const qs = require('qs');
const DropdownCategories = (props) => {
  const { navigation, onMouseEnter, onMouseLeave } = props;
  const { $size } = global;
  const dispatch = useDispatch();
  const { defaultProductImage } = global;
  const {
    list: categories,
    subcategories,
    childSubcategories,
    status: { list: statusApi },
    selectedCategoryKey,
    selectedSubCategoryId,
  } = useSelector((state) => state.categories);

  const groupCategory = useSelector(
    (state) => state.newLillaCategory.groupCategoryHome,
  );
  const typeCategory = useSelector(
    (state) => state.newLillaCategory.activeCategoryType.active_category,
  );
  const statusApiGroup = useSelector(
    (state) => state.newLillaCategory.status.groupCategoryHome,
  );

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const type = categories[selectedCategoryKey].slug;
  const categoryName = categories[selectedCategoryKey]?.name;
  const showNewCategoryLilla = typeCategory === 'group' && isLoggedIn;

  const filterGroupCategory = groupCategory
    ?.filter((category) => category.is_active)
    .map((category) => ({
      ...category,
      group_child: category.group_child.map((child) => ({
        ...child,
        show_on: category.show_on,
      })),
    }))
    .flat();

  const redirectToDetailHandler = async (item, types) => {
    let params = {
      slug: item?.slug,
      name: item?.name,
      type,
    };

    if (types === 'group' && isLoggedIn) {
      const mappedCategories = item.group_child.flatMap((category) => {
        return category.grand_child_category.map((grandChild) => {
          return {
            'categories.slug': grandChild.slug,
            ...(type === 'for-moms'
              ? { is_for_moms: true }
              : { is_for_baby_kids: true }),
          };
        });
      });

      const detailGroup = item.group_child.flatMap((category) =>
        category.grand_child_category.map((grandChild) => grandChild),
      );
      const slug = item?.slug ?? StringUtils.slugify(item?.group_name);
      params = {
        slug: `${item._id}-${slug}`,
        name: item?.group_name,
        type,
        newLillaText: item?.group_name,
        typeCategory: 'group',
        groupSlug: qs.stringify(mappedCategories),
        detailGroup: qs.stringify(detailGroup),
      };
    } else if (types === 'group-child' && isLoggedIn) {
      const mappedCategories = item.grand_child_category.map((category) => {
        return {
          'categories.slug': category.slug,
          ...(type === 'for-moms'
            ? { is_for_moms: true }
            : { is_for_baby_kids: true }),
        };
      });
      const slug = item?.group_slug ?? StringUtils.slugify(item?.name);
      params = {
        slug: `${generateFakeCategoryId()}-${slug}`,
        name: item?.name,
        type,
        newLillaText: item?.name,
        typeCategory: 'group',
        groupSlug: qs.stringify(mappedCategories),
        detailGroup: qs.stringify(item.grand_child_category),
      };
    }

    if (typeCategory !== 'group') {
      await dispatch({
        type: 'categories/setStatusApi',
        name: 'categoryInfo',
        value: 'idle',
      });
    }

    dispatch({ type: 'categories/hideMenuCategories' });
    dispatch({ type: 'newLillaCategory/resetGetMultipleProducts' });

    navigation.reset({
      index: 0,
      routes: [{ name: 'CategoryDetailScreen', params }],
    });
  };

  useEffect(() => {
    if (
      $size(categories) &&
      typeCategory !== 'group' &&
      !selectedSubCategoryId
    ) {
      dispatch({
        type: 'categories/setSelectedSubCategoryId',
        value: subcategories[categories[selectedCategoryKey].slug].data[0]._id,
      });
    } else if (
      typeCategory === 'group' &&
      $size(groupCategory) &&
      groupCategory[0].is_active &&
      !selectedSubCategoryId
    ) {
      dispatch({
        type: 'categories/setSelectedSubCategoryId',
        value: groupCategory[0]._id,
      });
    }
  }, [subcategories]);

  const selectSubCategoryHandler = (id) => {
    dispatch({ type: 'categories/setSelectedSubCategoryId', value: id });
  };

  useEffect(() => {
    const setDefaultTabActive = () => {
      // function to define default parent category Active/Selected
      if (showNewCategoryLilla) {
        const newCategoryLillaForMomsFlag = filterGroupCategory?.filter(
          (category) => category.show_on.includes('for_moms'),
        );
        const idParentCategoryDefault = $size(newCategoryLillaForMomsFlag)
          ? newCategoryLillaForMomsFlag[0]._id
          : 0;
        selectSubCategoryHandler(idParentCategoryDefault);
      } else {
        // category tree same like sociolla
        const idParentCategoryDefault =
          subcategories[categories[0]?.slug]?.data[0]._id;
        selectSubCategoryHandler(idParentCategoryDefault);
      }
    };

    setDefaultTabActive();
    return () => {
      const resetActiveTab = () => {
        dispatch({ type: 'categories/setSelectedCategoryKey', value: 0 });
        dispatch({ type: 'categories/setSelectedSubCategoryId', value: 0 });
      };
      resetActiveTab();
    };
  }, []);

  if (showNewCategoryLilla) {
    return (
      <View
        style={styles.container}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}>
        {statusApiGroup === 'loading' && (
          <ActivityIndicator
            activityIndicatorContainerStyle={{ marginVertical: 30 }}
          />
        )}

        {statusApiGroup === 'finished' && (
          <View style={styles.content}>
            <View style={styles.categoryTab}>
              {categories.map((item, key) => (
                <TouchableOpacity
                  key={`category-${key}`}
                  onMouseEnter={() => {
                    dispatch({
                      type: 'categories/setSelectedCategoryKey',
                      value: key,
                    });
                    const newCategoryLilla = filterGroupCategory?.filter(
                      (category) =>
                        category.show_on.includes(
                          item.is_for_moms ? 'for_moms' : 'for_baby_kids',
                        ),
                    );
                    const idParentCategoryDefault = $size(newCategoryLilla)
                      ? newCategoryLilla[0]._id
                      : 0;
                    selectSubCategoryHandler(idParentCategoryDefault);
                  }}
                  style={[
                    styles.categoryContainer,
                    selectedCategoryKey === key
                      ? styles.categoryContainerActive
                      : {},
                  ]}>
                  <Image
                    style={[
                      item.is_for_moms ? styles.imageMom : styles.imageBabyKids,
                      { marginBottom: 5 },
                    ]}
                    source={
                      item.is_for_moms
                        ? require('~assets/img/categories/mom.png')
                        : require('~assets/img/categories/mom-and-kids.png')
                    }
                  />

                  <Text style={styles.categoryText}>{item.name}</Text>
                  {Boolean(selectedCategoryKey === key) && (
                    <View
                      style={[
                        styles.categoryBorderLeft,
                        selectedCategoryKey === key ? { display: 'flex' } : {},
                      ]}></View>
                  )}
                </TouchableOpacity>
              ))}
            </View>
            <View style={styles.subCategoriesContainer}>
              <CustomScrollbar>
                {filterGroupCategory?.map((subcategory, key) => {
                  const categoryMapping =
                    categoryName === 'For Moms' ? 'for_moms' : 'for_baby_kids';
                  if (subcategory.show_on.includes(categoryMapping)) {
                    return (
                      <View
                        style={[
                          styles.groupSubCategoryItem,
                          selectedSubCategoryId === subcategory._id
                            ? styles.groupSubCategoryItemActive
                            : {},
                        ]}
                        key={`wrapperSubcategories_2${key}`}>
                        <TouchableOpacity
                          style={styles.subCategoryContainer}
                          onMouseEnter={() => {
                            selectSubCategoryHandler(subcategory._id);
                          }}>
                          <Image
                            style={styles.itemCardImage}
                            source={
                              subcategory[
                                categoryName === 'For Moms'
                                  ? 'logo_for_moms'
                                  : 'logo_for_baby_kids'
                              ]
                                ? {
                                    uri: imageCdn({
                                      config: 'w=70',
                                      image:
                                        subcategory[
                                          categoryName === 'For Moms'
                                            ? 'logo_for_moms'
                                            : 'logo_for_baby_kids'
                                        ],
                                    }),
                                  }
                                : defaultProductImage
                            }
                          />
                          <Text
                            style={[
                              styles.subcategoryName,
                              selectedSubCategoryId === subcategory._id
                                ? styles.subcategoryNameActive
                                : {},
                            ]}>
                            {subcategory.group_name}
                          </Text>
                        </TouchableOpacity>
                      </View>
                    );
                  } else {
                    return null;
                  }
                })}
              </CustomScrollbar>
            </View>
            <View style={styles.containerSecondaryMenu}>
              <CustomScrollbar>
                <View style={styles.secondaryMenu}>
                  {selectedSubCategoryId && typeCategory === 'group' ? (
                    <View style={styles.secondaryMenuContent}>
                      <TouchableOpacity
                        onPress={() => {
                          redirectToDetailHandler(
                            groupCategory?.find(
                              (flag) => flag._id === selectedSubCategoryId,
                            ),
                            'group',
                          );
                        }}>
                        <Text style={styles.titleLink}>
                          See all{' '}
                          {
                            groupCategory.find(
                              (flag) => flag._id === selectedSubCategoryId,
                            )?.group_name
                          }
                        </Text>
                      </TouchableOpacity>
                      <View>
                        {filterGroupCategory
                          ?.find((flag) => flag._id === selectedSubCategoryId)
                          ?.group_child.map((childSubcategory, key) => (
                            <View
                              style={styles.groupCategoryItem}
                              key={`wrapperSubcategories${key}`}>
                              <TouchableOpacity
                                style={[]}
                                onPress={() => {
                                  redirectToDetailHandler(
                                    childSubcategory,
                                    'group-child',
                                  );
                                }}>
                                <Text style={styles.titleLink}>
                                  {childSubcategory?.name}
                                </Text>
                              </TouchableOpacity>
                            </View>
                          ))}
                      </View>
                    </View>
                  ) : null}
                </View>
              </CustomScrollbar>
            </View>
          </View>
        )}
      </View>
    );
  }

  return (
    <View
      style={styles.container}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      {statusApi === 'loading' && (
        <ActivityIndicator
          activityIndicatorContainerStyle={{ marginVertical: 30 }}
        />
      )}

      {statusApi === 'finished' && (
        <View style={styles.content}>
          <View style={styles.categoryTab}>
            {categories.map((item, key) => (
              <TouchableOpacity
                key={`category-${key}`}
                onMouseEnter={() => {
                  dispatch({
                    type: 'categories/setSelectedCategoryKey',
                    value: key,
                  });
                  dispatch({
                    type: 'categories/setSelectedSubCategoryId',
                    value: subcategories[categories[key].slug].data[0]._id,
                  });
                }}
                style={[
                  styles.categoryContainer,
                  selectedCategoryKey === key
                    ? styles.categoryContainerActive
                    : {},
                ]}>
                <Image
                  style={[
                    item.is_for_moms ? styles.imageMom : styles.imageBabyKids,
                    { marginBottom: 5 },
                  ]}
                  source={
                    item.is_for_moms
                      ? require('~assets/img/categories/mom.png')
                      : require('~assets/img/categories/mom-and-kids.png')
                  }
                />

                <Text style={styles.categoryText}>{item.name}</Text>
                {Boolean(selectedCategoryKey === key) && (
                  <View
                    style={[
                      styles.categoryBorderLeft,
                      selectedCategoryKey === key ? { display: 'flex' } : {},
                    ]}></View>
                )}
              </TouchableOpacity>
            ))}
          </View>
          <CustomScrollbar>
            <View style={styles.subCategoriesContainer}>
              {subcategories[categories[selectedCategoryKey].slug].data.map(
                (subcategory, key) => (
                  <View
                    style={[
                      styles.groupSubCategoryItem,
                      selectedSubCategoryId === subcategory._id
                        ? styles.groupSubCategoryItemActive
                        : {},
                    ]}
                    key={`wrapperSubcategories_2${key}`}>
                    <TouchableOpacity
                      style={styles.subCategoryContainer}
                      onMouseEnter={() => {
                        selectSubCategoryHandler(subcategory._id);
                      }}>
                      <Image
                        style={styles.itemCardImage}
                        source={
                          subcategory.lulla_logo
                            ? {
                                uri: imageCdn({
                                  config: 'w=70',
                                  image: subcategory.lulla_logo,
                                }),
                              }
                            : defaultProductImage
                        }
                      />
                      <Text
                        style={[
                          styles.subcategoryName,
                          selectedSubCategoryId === subcategory._id
                            ? styles.subcategoryNameActive
                            : {},
                        ]}>
                        {subcategory.name}
                      </Text>
                    </TouchableOpacity>
                  </View>
                ),
              )}
            </View>
          </CustomScrollbar>
          <View style={styles.containerSecondaryMenu}>
            <CustomScrollbar>
              <View style={styles.secondaryMenu}>
                {selectedSubCategoryId &&
                childSubcategories[
                  `${selectedSubCategoryId}_${categories[selectedCategoryKey].slug}`
                ] ? (
                  <View style={styles.secondaryMenuContent}>
                    <TouchableOpacity
                      onPress={() => {
                        redirectToDetailHandler(
                          childSubcategories[
                            `${selectedSubCategoryId}_${categories[selectedCategoryKey].slug}`
                          ],
                        );
                      }}>
                      <Text style={styles.titleLink}>
                        See all{' '}
                        {
                          childSubcategories[
                            `${selectedSubCategoryId}_${categories[selectedCategoryKey].slug}`
                          ].name
                        }
                      </Text>
                    </TouchableOpacity>
                    <View>
                      {childSubcategories[
                        `${selectedSubCategoryId}_${categories[selectedCategoryKey].slug}`
                      ].data.map((childSubcategory, key) => (
                        <View
                          style={styles.groupCategoryItem}
                          key={`wrapperSubcategories${key}`}>
                          <TouchableOpacity
                            style={[]}
                            onPress={() => {
                              redirectToDetailHandler(childSubcategory);
                            }}>
                            <Text style={styles.titleLink}>
                              {childSubcategory.name}
                            </Text>
                          </TouchableOpacity>
                        </View>
                      ))}
                    </View>
                  </View>
                ) : null}
              </View>
            </CustomScrollbar>
          </View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  categoryContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.purple34,
    padding: 12,
  },
  categoryContainerActive: {
    backgroundColor: Colors.pastel9,
  },
  categoryTab: {
    backgroundColor: Colors.purple34,
    width: 90,
  },
  imageBabyKids: {
    width: 27,
    height: 24,
  },
  imageMom: {
    width: 24,
    height: 24,
  },
  categoryBorderLeft: {
    position: 'absolute',
    left: 0,
    top: 12,
    bottom: 12,
    width: 4,
    backgroundColor: Colors.red4,
    borderTopRightRadius: 2,
    borderBottomRightRadius: 2,
  },
  categoryText: {
    fontFamily: 'satoshi-bold',
    fontSize: 13,
    lineHeight: 16,
    color: Colors.purple4,
    textAlign: 'center',
  },
  container: {
    ...(isWeb && {
      boxShadow: '0 11px 11px 0 rgba(0,0,0,0.32)',
    }),
    position: 'absolute',
    top: 45,
    left: 0,
    // backgroundColor: Colors.pastel9,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    overflow: 'hidden',
  },
  content: {
    flexDirection: 'row',
    height: 250,
  },
  groupSubCategoryItem: {
    paddingHorizontal: 8,
    paddingVertical: 5,
  },
  groupSubCategoryItemActive: {
    backgroundColor: Colors.white,
    borderTopLeftRadius: 6,
    borderBottomRightRadius: 6,
  },
  groupCategoryItem: {},
  secondaryMenu: {
    flex: 1,
    paddingHorizontal: 16,
    paddingVertical: 14,
    paddingRight: 0,
  },
  secondaryMenuContent: {
    paddingRight: 60,
  },
  subCategoriesContainer: {
    paddingHorizontal: 8,
    paddingVertical: 10,
    width: 180,
    backgroundColor: Colors.pastel9,
  },
  subCategoryContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  subcategoryName: {
    fontFamily: 'satoshi-black',
    color: Colors.grey17,
    fontSize: 13,
    lineHeight: 20,
  },
  subcategoryNameActive: {
    color: Colors.purple4,
  },
  titleLink: {
    fontFamily: 'satoshi-bold',
    color: '#89828E',
    lineHeight: 20,
    fontSize: 14,
    marginBottom: 12,
  },
  itemCardImage: {
    borderRadius: 24 / 2,
    width: 24,
    height: 24,
    marginRight: 10,
  },
  containerSecondaryMenu: {
    backgroundColor: Colors.white,
    width: 198,
  },
});

export default DropdownCategories;
